import React, { useState } from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import Lightbox from 'react-image-lightbox';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Box from '@mui/material/Box';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import Button from '@mui/material/Button';

import Grid from '@mui/material/Grid';
import Main from 'layouts/Main';
import p1 from 'assests/Photos/Clubs/Humanity/nationalyouthday/1.jpg';
import p2 from 'assests/Photos/Clubs/Humanity/nationalyouthday/2.jpg';
import p3 from 'assests/Photos/Clubs/Humanity/nationalyouthday/3.jpg';
import p4 from 'assests/Photos/Clubs/Humanity/nationalyouthday/4.jpg';
import p5 from 'assests/Photos/Clubs/Humanity/nationalyouthday/5.jpg';
import p6 from 'assests/Photos/Clubs/Humanity/nationalyouthday/6.jpg';
import p7 from 'assests/Photos/Clubs/Humanity/nationalyouthday/7.jpg';
import p8 from 'assests/Photos/Clubs/Humanity/nationalyouthday/8.jpg';


// import Ecoclub2023 from 'views/EnvironmentDay2023/Ecoclub2023';


import { Typography } from '@mui/material';
import Container from 'components/Container';
// import Sidebar2023 from './Sidebar2023';
import Sidebar2023 from 'views/Humanity2023/Sidebar2023';
import Sidebar2022 from 'views/HumanityClub/components/Sidebar2022';


const NationalyouthDay2023 = () => {
  const theme = useTheme();
  const [currentImage, setCurrentImage] = useState(0);
  const [viewerIsOpen, setViewerIsOpen] = useState(false);

  const openLightbox = (index) => {
    setCurrentImage(index);
    setViewerIsOpen(true);
  };

  const closeLightbox = () => {
    setCurrentImage(0);
    setViewerIsOpen(false);
  };

  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });

  const photos = [
    {
      src: p1,
      source: p1,
      rows: 1,
      cols: 1,
    },
    {
          src: p8,
          source: p8,
          rows: 1,
          cols: 1,
        },
  
    {
      src: p2,
      source:p2,
      rows: 1,
      cols: 2,
    },
    {
      src: p3,
      source: p3,
      rows: 1,
      cols: 2,
    },
    {
      src: p4,
      source: p4,
      rows: 1,
      cols: 1,
    },
    {
        src: p5,
        source: p5,
        rows: 1,
        cols: 1,
      },
      {
        src: p6,
        source: p6,
        rows: 1,
        cols: 2,
      },
      {
        src: p7,
        source: p7,
        rows: 1,
        cols: 2,
      },
      
  ];

  return (
    <Main>
   
<Container>

  <Grid container spacing={4}>
            <Grid item xs={12} md={8}>
    <Box>
        
      <Box >
      <Typography variant='h4' align='center' >
      NATIONAL YOUTH DAY
        </Typography>
        {/* <Typography
        fontSize = "x-small"
        ><br/><br/>
      Class: 8 - 12  &  Date: 27 JUNE 2023
        </Typography> */}

        <br />
        <Typography variant={'subtitle1'} align={'justify'}>
        Every year 12th  January  is observed as National Youth Day in India to commemorate the birth anniversary of Swami Vivekananda, a great philosopher and spiritual leader. 
        <br></br>
        National Youth Day was also celebrated in National Public School, Yeshwanthpur with the objective of creating awareness and providing knowledge about the rights of people in India. The aim was also to motivate the students to make a better future for their country and to follow the ideals  of Swami Vivekananda like hardwork, honesty, perseverance, self-control and simplicity.
        <br></br>
        Myriad activities were conducted to inculcate positive values among the youngsters. The students participated in all the activities very enthusiastically. They performed mime which depicted the values taught and practiced by Swami Vivekananda. It was followed by a poster making activity and yoga-cum-meditation session, where the students participated whole-heartedly.
        <br></br>
        The speech by the students and the insights provided by the teacher on the topic ‘ National Youth Day’ highlighted the roles and responsibilities of today’s youth towards their  country.
        {/* <br></br>
        The Principal Ms Sheeja Manoj, addressed the students and highlighted the importance of keeping themselves updated 
        on the environmental concerns and being thoughtful about their actions. The students thoroughly enjoyed the activity 
        and it proved to be a valuable learning experience. */}
        </Typography>
      </Box>
      {/* <Typography
          variant={'h6'}
          color={'primary'}
          align={'center'}
          fontWeight={'normal'}
        ><br/>
        “ALL FOR MUN, MUN FOR ALL”

<br/>
        </Typography> */}

      <Box>
        <ImageList
          variant="quilted"
          cols={2}
          rowHeight={isMd ? 300 : 200}
          gap={isMd ? 16 : 4}
        >
          {photos.map((item, i) => (
            <ImageListItem key={i} cols={item.cols} rows={item.rows}>
              <LazyLoadImage
                height={'100%'}
                width={'100%'}
                src={item.src}
                alt="..."
                effect="blur"
                onClick={() => openLightbox(i)}
                style={{
                  objectFit: 'cover',
                  filter:
                    theme.palette.mode === 'dark' ? 'brightness(0.7)' : 'none',
                  cursor: 'poiner',
                  borderRadius: 8,
                }}
              />
            </ImageListItem>
          ))}
        </ImageList>
      </Box>
      {viewerIsOpen && (
        <Lightbox
          mainSrc={photos[currentImage].src}
          nextSrc={photos[(currentImage + 1) % photos.length].src}
          prevSrc={
            photos[(currentImage + photos.length - 1) % photos.length].src
          }
          onCloseRequest={() => closeLightbox()}
          onMovePrevRequest={() =>
            setCurrentImage((currentImage + photos.length - 1) % photos.length)
          }
          onMoveNextRequest={() =>
            setCurrentImage((currentImage + 1) % photos.length)
          }
          reactModalStyle={{ overlay: { zIndex: 1500 } }}
        />
      )}
    </Box>
    </Grid>

    <Grid item xs={12} md={4}>
             
             <Box marginBottom={4}>
               <Sidebar2023 />
             </Box>

             <Box marginBottom={4}>
               <Sidebar2022 />
             </Box>

    </Grid>
    </Grid> 
    </Container>
    </Main>
   
   
  );
};

export default NationalyouthDay2023;